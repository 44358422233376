body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

@font-face {
  font-family: 'Mona-sans-regular';
  src: url('../src/utils/fonts/Mona-Sans-Regular.ttf');
}

@font-face {
  font-family: 'Mona-sans-semibold';
  src: url('../src/utils/fonts/Mona-Sans-SemiBoldWide.ttf');
}

@font-face {
  font-family: 'Mona-sans-black';
  src: url('../src/utils/fonts/Mona-Sans-Black.ttf');
}

@font-face {
  font-family: 'AvenirLStd-book';
  src: url('./utils/fonts/AvenirLTStd-Book.otf');
}

@font-face {
  font-family: 'AvenirLStd-Heavy';
  src: url('./utils/fonts/AvenirLTStd-Heavy.otf');
}