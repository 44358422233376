.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 3rem;
    height: 1rem;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 2px;
    margin: auto;
    font-family: 'Gotham';
    /* background-color: white; */
    padding-bottom: 2rem;
    z-index: 999;
}

@font-face {
    font-family: 'Gotham';
    src: url('../../utils/fonts/Gotham-Light.otf');
}

.authItems {
    display: flex;
    list-style-type: none;
    width: 20%;
    justify-content: space-around;
    align-items: center;
}

.authItems span{
    text-align: center;
    margin-right: 0.5rem;
}

.authItems span img{
    width: 20px;
    height: 20px;
}
.authItems span img:hover{
    cursor: pointer;
}
.authItems a {
    padding: 0.5rem;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    color: black;
}

.authItems a:nth-child(2):hover {
    background-color: #e2e2e2;
    border-radius: 0.4rem;
    cursor: pointer;
}

.authItems a:nth-child(3) {
    border: 1.5px solid #d1d1d1;
    border-radius: 0.4rem;
}

.authItems a:nth-child(3):hover {
    background-color: #e2e2e2;
    cursor: pointer;
}

.hamburger {
    display: none;
}

.dropdownSpace{
   font-weight: 700;
   font-size: 12px ;
   padding: 0.2rem 0.5rem 0.2rem 0.5rem ;
   border-radius: 20px ;
}

.dropdownSpace svg{
    font-size: 18px ;
    background-color: rgba(205, 205, 205, 0.36);
    border-radius: 100%;
    padding: 7px;
}

.dropdownSpace:hover{
    color: #094AAD;
    cursor: pointer;
    background-color: rgba(205, 205, 205, 0.72);
}

.dropdownOverlay{
    text-align: center;
}

.drawer{
    display: none;
}


@media (max-width: 480px){
    .header{
        width: 83%;
        background-color: white;
        z-index: 999;
        padding: 2rem;
        padding-bottom: 1rem;
    }

    .header img{
        width: 6rem;
        margin-left: 0;
    }

    .hamburger{
        display: block;
        z-index: 999;
    }

    .hamburger:hover{
        cursor: pointer;
    }

    .authItems {
        display: none;
    }

    .drawer{
        display: block;
        background-color: white;
        height: 25vh;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top : 0;
        z-index: 1;
        transition: all 0.5s ease-in-out;
    }

    .backdrop{
        position: absolute;
        background-color: #8c8c8ca1;
        backdrop-filter: blur(20);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }

    .drawer button{
        width: 90%;
        margin-left: 5%;
        height: 2.5rem;
        font-weight: 600;
    }

    .drawer button:nth-child(1){
        margin-top: 5rem;
        margin-bottom: 1rem;
    }
    .profileViewerRes{
        margin-top: 5rem;
        height: fit-content;
    }
    /* .drawer button{
       width: 80%;
    }

    .drawer button:nth-child(2){
        margin-top: 1rem;
    }

    .drawerRoot{
        z-index: 999;
    } */

    .dropdownSpace{
        display: block;
        color: #094AAD;
        display: flex;
        font-size: 12px;
        justify-content: space-around;
    }

    .dropdownSpace svg{
        margin: 1rem;
        size: 10px;
        background-color: transparent;
    }

    .dropdownSpace:hover{
        background-color: transparent;
    }
}