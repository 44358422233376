.headDiv {
    padding-top: 1rem;
    padding-left: 10rem;
}

.headDiv img {
    width: 8rem;
}

.mainDiv {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.form {
    width: 45%;
}

.fileName {
    margin: 6px 0px;
    font-size: 1rem;
}
.codeBlock{
    margin-top: 1.8rem;
    position: sticky;
}
.outputComponent {
    width: 45%;
    display: flex;
    flex-direction: column;
    height: 80vh;
    max-height: 80vh;
    margin-top: 5.4rem;
    border: 1px solid darkgray;
    border-radius: 0.4rem;
    overflow: scroll;
    color: #FCFCFC;
}

.outputComponent h4 {
    position: absolute;
    top: 8.1rem;
    font-size: 1rem;

}

.actions {
    width: 200%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}


.actions div:nth-child(2){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 16%;
}


.langSelect:hover {
    cursor: pointer;
}

.runButton {
    /* padding: 0.3rem 1rem;
    margin-bottom: 1rem;
    border: none;
    display: flex;
    align-items: center;
    height: 35px;
    justify-content: center;
    font-size: 16px;
    margin-top: 4px; */
    font-weight: 700;
}

/* .shareButton{
    background-color: rgba(233, 233, 233, 0.618);
    border: 1px solid lightgrey;
}
.shareButton:hover{
    color: white;
    border: none;
} */

.shareButton{
    display: flex;
    align-items: center;
    width: fit-content;
}
 .shareButton svg{
    margin-right: 0.5rem;
}

.runButton:hover {
    background-color: #7aeb8dc2;
    cursor: pointer;
}

.runButton span {
    margin-left: 8px;
}


.textCursor {
    color: #7aeb8d;
    width: 200%;
    font-weight: bold;
    background-color: black;
    opacity: 0.5;
    pointer-events: none;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.Terminal {
    background-color: lightgray;
    font-weight: bolder;
    height: 75vh;
    color: black;
    display: flex;
    align-items: baseline;
    letter-spacing: 0.3px;
    font-size: 14px;
    flex-direction: column;
}

.Terminal div {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.outputForConsoleForm input {
    background-color: transparent;
    width: fit-content;
    border: none;
    outline: none;
}


.outputForConsoleForm {
    width: 10px;
    padding: 0;
    margin: 0;
    z-index: 999;
    display: flex;
    align-self: center;
}

.promptLabel {
    margin: 1rem 0 0 1rem;
}

.output {
    margin: 0;
    padding-left: 8px;
    width: fit-content;
    height: fit-content;
    white-space: pre-line;
}

.commandMessage {
    padding: 0;
    padding-left: 1rem;
    color: black;
    z-index: 999;
    white-space: pre-line;
}

.langSelect {
    width: 16%;
    max-height: 20rem;
}

.langSelect div:hover {
    cursor: pointer;
 color: #74747486;
}

.userInputField{
    margin: auto;
    margin-top: 1rem;
    width: 95%;
    margin-bottom: 0.5rem;
    height: 8%;
}

.mainDivRes div{
    display: none;
}


@media  (max-width : 480px){
    .mainDivRes{
        height: 100vh;
        margin-top: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .mainDivRes div{
       display: flex;
       justify-content: center;
       flex-direction: column;
       width: 80%;
       align-items: center;
       margin: auto;
       display: block;
    }

    .mainDivRes p{
        font-size: 10px;
        margin-bottom: 0;
        text-align: center;
    }

    .divider{
       margin-top: 0px;
    }

    .mainDiv{
        display: none;
    }

    .mainDivRes button{
        font-size: 12px;
        width: 100%;
        margin-top: 1rem;
    }
}