@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&display=swap');

.main {
    width: 100%;
    height: 80vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 2px;
    background-image: linear-gradient(180deg, #FFF , #dfe7ff );
    margin-top: 6rem;
    box-sizing: border-box;
    border-radius: 40px;
}


.h1 {
    font-family: 'Mona-sans-black', serif;
    font-size: 32px;
    margin-bottom: 0;
    transition: all 0.2s ease;
    margin-top: -4rem;
}


.main p {
    padding: none;
    margin-bottom: 3rem;
}

.cta {
    flex: 0 0 auto;
    font-size: 14px;
    background-color: black;
    color: white;
    border: none;
    font-weight: bolder;
    border-radius: 20px;
    padding: 0.5rem 2rem;
    margin: 3rem;
    width: fit-content;
    height: 3rem;
    position: relative;
    transition: 0.5s ease;
    margin-top: -2rem;
    text-decoration: none;
}

.cta::after {
    content: '';
    border-radius: 4px;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: #00DBDE;
    background-image: linear-gradient(45deg, #00DBDE 0%, #FC00FF 100%);
    filter: blur(20px);
    transition: 0.2s ease;
    margin-left: -11.5rem;
    margin-top: 0;
}

.cta:hover {
    cursor: pointer;
    color: white;
}

.ctaWrap {
    transition: transform 0.2s ease-in-out;
    margin-top: -1rem;
}


.ctaWrap:hover {
    transform: scale(1.2);
}

.about{
    height: fit-content;
    width: 100%;
    display: flex;
    font-family:'Josefin Sans', serif;
    text-align: center;
    flex-direction: column;
    padding-bottom: 10rem;
    justify-content: center;
    align-items: center;
}
.about img{
    margin: -10rem auto 5rem auto;
    z-index: 999;
}
.about h1{
    font-family:'Josefin Sans', sans-serif;
    margin-bottom: 1rem;
}

.logoname{
    color: #004AAD;
}

.about p{
    font-family:'Josefin Sans', sans-serif;
    font-weight: 400;
    padding: 0 20%;
    line-height: 22px;
    letter-spacing: 1px;
    padding-top: 0;
    margin-top: 0;
}

.exploreBtn{
    width: fit-content;
    background-color: rgb(47, 47, 47);
    border: 2px solid transparent;
    outline: none;
    padding: 0.8rem 1rem;
    color: white;
    border-radius: 1.4rem;
    font-family:'Josefin Sans', sans-serif;
    font-weight: 800;
    letter-spacing: 1px;
    transition: .3s;
}

.exploreBtn:hover{
    cursor: pointer;
    box-shadow: 0px 10px 40px #004AAD;
    transform: scale(1.05,1.05);
}

.features {
    width:90%;
    height: 30rem;
    margin-top: 4rem;
    text-align: left;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: center;
    gap: 1rem;
    font-family: 'Inter', sans-serif;
}

.features div{
    border: 1px solid #dedede;
    background-image: linear-gradient(150deg, #f6f8ff 0%, #FFF 53.16%, #f6f8ff 100%);
    border-radius: 4px;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(110, 110, 110, 0.15) 0px 2px 8px;
}

.features div p{
    line-height:normal;
    letter-spacing: normal;
    font-size: 12px;
    padding: 0;
    font-family: 'Inter', sans-serif;
}
.features div h3{
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 600;
}
.features div img{
    width: 40px;
    height: 40px;
    margin-top: 8px;
    margin: 8px 0 0 0;
    padding: 0;
}

.features div:nth-child(1){
    grid-column: span 2;
}

.features div:nth-child(2){
    grid-column: span 3;
}

.features div:nth-child(3){
    grid-column: span 2;
    grid-row: span 2;
    
}

.features div:nth-child(4){
    grid-column: span 3;
}

.features div:nth-child(5){
    grid-column: span 2;
}


/* Phone Size */
@media (max-width: 480px) {
    .main {
      width: 95%;
      margin-top: 4rem;
      background-image : none;
      height: 100vh;
    }

    .main span{
       margin-top: -10rem;
    }
    .main p {
        padding: none;
        margin-bottom: 2rem;
        font-size: 10px;
        width: 80%;
    }    
  
    .h1 {
      font-size: 24px;
    }
  
    .cta {
      font-size: 16px;
    }
    .cta::after{
        display: none;
    }
  
    .about img {
        width: 90%;
        display: none;
      /* margin: -6rem auto 2rem auto; */
    }
  
    .about p {
      padding: 0 5%;
      font-size: 10px;
    }

    .about h1{
        font-size: 22px;
        width: 80%;
    }

    .about > p{
        /* background-color: #004AAD; */
        width: 80%;
        line-height: 14px;
    letter-spacing: 1px;
    }
  
    .features {
      height: auto;
      grid-template-columns: repeat(2, 1fr);
    }

    .features div img{
        display: block;
    }

    .features div{
        height: 14rem;
    }

    .features div:nth-child(1),
    .features div:nth-child(2),
    .features div:nth-child(3),
    .features div:nth-child(4),
    .features div:nth-child(5) {
      grid-column: span 2;
      grid-row: auto;
    }
  }
  