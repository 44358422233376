@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&display=swap');

.openAI {
    color: grey;
    height: 100%;
    margin-bottom: 0;
    border-radius: 4px;
    font-family: "Inter", sans-serif;
}

.prompt {
    display: flex;
    align-items: baseline;
    width: 100%;
}

/* .sendBtn svg {
    rotate: 55deg;
} */

.sendBtn svg:hover {
    cursor: pointer;
}


.modeDropdown {
    width: fit-content;
    margin: 1rem;
    cursor: pointer;
    font-size: 12px;
    width: 250px;
    font-weight: 700;
}

.modeDropdown div:hover {
    cursor: pointer;
}

.openAIresponse {
    padding: 0.5rem 1rem;
    padding-top: 0;
    width: 100%;
    box-sizing: border-box;
    word-wrap: normal;
    word-break: normal;
}

.openAIresponse pre {
    background-color: aliceblue;
}



.textCursor{
  position: absolute;
  display: inline-block;
  width: 8px;
  height: 16px;
  background-color: rgb(106, 106, 106);
  animation: blink 0.8s infinite; 
}

@keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  
.langaugeToInput{
    border: none;
    width: fit-content;
    border-bottom: 1px solid blue;
    outline: 1px solid lightgray;
    height: 22px;
    padding-left: 4px;
}