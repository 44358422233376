.preLoader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preLoader img {
    animation: logoVis 2s;
}

@keyframes logoVis {
    0% {
        opacity: 0.1;
    }

    100% {
        opacity: 1.0;
    }
}