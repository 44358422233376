@font-face {
    font-family: 'Gotham';
    src: url('../../utils/fonts/Gotham-Light.otf');
}

.footer {
    height: 4rem;
    width: 90%;
    position: relative;
    bottom: 4rem;
    font-weight: bold;
    font-family: 'Gotham';
    font-size: 12px;
    letter-spacing: 1px;
    color: rgb(89, 89, 89);
    margin: auto;
}

.logo img {
    width: 7rem;
    display: none;
}

.copyrightsection {
    display: flex;
    justify-content: space-between;
}

.secondsec {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.secondsec p:nth-child(2) {
    margin-left: 1rem;
}
.footerInLogo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.footerInLogo img{
    display: block;
}

.developer a{
    color: rgb(89, 89, 89);
    text-decoration: none;
}
@media (max-width: 480px){
    .logo img {
        width: 5rem;
        display: none;
    }


    .footer {
        font-size: 8px;
    }

    .copyrightsection{
        display:flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }


    .secondsec{
        display: flex;
        flex-direction: column;
        /* padding-bottom: 1rem ; */
        justify-content: space-between;
    }

    .secondsec p:nth-child(2){
        margin: 0;
    }

    .footerInLogo img{
        display: block;
        width: 5rem;
    }
}