.copyIcon {
    z-index: 999;
}
.copyIcon:hover{
    cursor: pointer;
}


.codeSnippet {
    font-size: 12px;
    padding: 4px;
    border-radius: 4px;
}
.codeSnippetHead{
    background-color:transparent;
    border-radius: 4px 4px 0px 0px;
    color: rgb(78, 78, 78);
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    padding: 4px;
    padding-top: 0;
    margin-bottom: 0.5rem;
}
.codeSnippetHead span{
    font-weight: 700;
    letter-spacing: 1px;
    font-size:14px ;
}

.mainDiv{
    font-weight: bolder;
}