.main{
    /* background-color: aqua; */
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.main > div{
    height: fit-content;
    width: 30%;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border: 1px solid rgba(196, 196, 196, 0.265);
    border-radius: 8px;
    padding: 1rem;
    transition: height 1s ease-in;
}

.main h4{
    margin-bottom: 8px ;
}

.main div p{
    font-size: 12px;
    margin: 0;
}

.main div img{
    margin-top: 2rem;
    width: 6rem;
}

.form {
    font-size: 12px;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.form label{
    margin-bottom: 0.3rem;
}

.form input { 
    height: 1.5rem;
    padding: 4px;
    border-radius: 6px;
    border: 1px solid rgba(142, 142, 142, 0.483);
    transition: box-shadow 0.3s ease;
    letter-spacing: 1px;
    font-weight: 500;
    color: rgb(53, 53, 53);
}

.form input:nth-child(2){
    margin-bottom: 0.8rem;
}

.form > span {
    margin: 4px;
    margin-bottom: 0.8rem;
    /* font-size: 8px; */
    color: rgb(255, 0, 0);
}

.form > span p{
    font-size: 9px;
}

.form a{
    margin-bottom: 1rem;
    text-align: right;
    font-weight: 600;
    text-decoration: none;
}


.form button{
    padding: 8px;
    background-color: #094AAD;
    border-radius: 6px;
    border: 1px solid transparent;
    color: white;
    font-weight: 700;
    transition: background-color 0.3s ease;
}

.form button:hover{
    background-color: #094badde;
    cursor: pointer;
}

.form input:focus-visible{
    outline: 1px solid rgba(0, 0, 0, 0.1) ;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.noAccount{
    padding: 2rem;
    padding-bottom: 0;
    text-align: center;
}

.noAccount span{
    text-decoration: none;
    font-weight: 800;
}
.noAccount span:hover{
    cursor: pointer;
}
.partition{
    font-size: 12px;
    margin: 1rem 0;
    color: #094AAD;
    font-weight: bolder;
}

.Dlogin{
    display: flex;
    flex-direction: column;
    margin: 1rem 0.2rem;
}

.Dlogin button{
    margin-bottom: 0.8rem;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid rgba(142, 142, 142, 0.483);
    transition: all 0.3s ease;
    width: 100%;
    background-color: #edededaf;
}

.Dlogin button:last-child{
    margin-bottom: 0;
}

.Dlogin button{
    display: flex;
    align-items: center;
    justify-content :center;
    padding: 4px;
}

.Dlogin button img,
.Dlogin button svg{
    width: 24px;
    margin: 0;
    margin-right: 1rem;
}
.Dlogin button svg{
    width: 24px;
    height: 28px;
}
.Dlogin button:hover{
    background-color: #ededed5a;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.goBack{
    position: absolute;
    text-decoration: none;
    font-size: 12px;
    margin-bottom: 0;
    font-weight: bold;
}

.loadingIcon{
    animation: load infinite;
}

@keyframes load{
    0%{
        transform: rotate(180deg);
    }
}

@media (max-width: 480px){
    .main > div{
        width: 80%;
        margin-top: -2rem;
    }
    .Dlogin button{
        width: 100%;
    }

    .main h4{
        margin-top: 0.5rem;
    }

    .noAccount{
        padding-top: 1rem ;
    }

}